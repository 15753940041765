import { Alert, Button, Intent } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';

import IfAuth from '../components/IfAuth';
import ContextualNav from '../components/nav/ContextualNav';
import { useGet } from '../utils/hooks';

import './siteList.css'
import DeploymentLog from '../components/DeploymentLog';

const DeployDetailPage = () => {
  const {history, match: { path: matchPath, url: matchUrl, params: { id } }} = useReactRouter();
  const [currentDeploy, setCurrentDeploy] = useState()
  const [showForm, setShowForm] = useState(false)
  const [currentSite, setCurrentSite] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const {data: siteResponse, isLoading: fetchingSites, run: fetchSite, error: fetchSiteError} = useGet(`/deploy/${id}`, null, {
    onResolve: (response) => {
      if(response.data) {
        setCurrentDeploy(response.data.data)
      }
    },
    onReject: error => {
      console.error("Deploys error:", error);
    }
  });

  const {isLoading: sitePublishing, run: publishDeploy, error: sitePublishError} = useGet(`/deploy/publish/${currentDeploy && currentDeploy.id}`, {}, {
    onResolve: (response) => {
        setShowWarning(false)
    }
  });

  useEffect(() => {
    fetchSite()
  }, [])

  const deploySiteVersion = () => {
    setShowWarning(true);
  }


  return (<IfAuth>
      <div>
          <ContextualNav></ContextualNav>
      </div>

    <div className='pa4 site-list' style={{
      background: 'white',
      marginTop: '50px'
    }}>
      {
        !currentDeploy ? <h3>Fetching details...</h3> :
        <div>
          <div className='f6 w-100 center mw8 mb5 justify-between items-center flex flex-column'>
            <h4>{currentDeploy.site.name}</h4>
            <h5>{currentDeploy.commit}</h5>
            <h6>{currentDeploy.commit_message}</h6>
            <div>
                      {
                        (currentDeploy.id == currentDeploy.site.published_deploy_id) ? 
                        <Button intent='success' disabled={true}>Live</Button>:
                        <Button intent='primary'  onClick={() => {
                          deploySiteVersion()
                        }}>Publish</Button>
                      }
                      
            </div>
            
          </div>

          <div className='w-100'>
              {
                currentDeploy.status === 'completed' ? <h4>Site is Live</h4> : <DeploymentLog build={currentDeploy}></DeploymentLog>
              }
          </div>
          <div>
          </div>
        </div>
      
      }
    </div>

    <Alert
        
        cancelButtonText="Cancel"
        confirmButtonText="Publish this deploy"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={showWarning}
        loading={sitePublishing}
        onCancel={() => {setShowWarning(false)}}
        onConfirm={publishDeploy}
    >
        <p>
            Are you sure you want to publish this version?
        </p>
    </Alert>
    
  </IfAuth>);
};

export default DeployDetailPage;