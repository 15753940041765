import React, {useContext, useState} from 'react';
import {InputGroup, Button, Card, Elevation, Dialog} from '@blueprintjs/core';
import Joi from '@hapi/joi';

import AuthContext from '../components/contexts/Auth';
import {wireEventValue} from '../utils/func';

import useReactRouter from 'use-react-router'; 
import {queryStringToObj} from '../utils/string';
import {usePost} from '../utils/hooks';
import FormError from '../components/FormError';
import vsLogo from '../images/v.png';
import checkIcon from '../images/icons/check-circle/16x16.png';
import loginIllustration from '../images/illustrations/login-illustration.svg';
import {Link} from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import LogoFullDark from "../images/logo.png";
import GreenTick from "../images/latest/tick-green.svg";

const schema = Joi.object().keys({
  phoneNumber: Joi.string().regex(/^\+[1-9][0-9]{11}$/).required().error(() => "Please enter a valid email or phone number, ex: 981xxxxxxx"), 
});

const finalSchema = Joi.object().keys({
  phoneNumberOrEmail: Joi.alternatives().try(
    Joi.string().regex(/^\+[1-9][0-9]{11}$/).required()
      .error(() => "Please enter a valid email or phone number, ex: 981xxxxxxx"), 
    Joi.string().email({minDomainSegments: 2}).required()
      .error(() => "Please enter a valid email")
  ),
  otp: Joi.string().required().error(() => 'Please enter the otp.'),
  newPassword: Joi.string().regex(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/).required()
                  .error(() => "Please provide a password with atleast 1 lowercase, 1 uppercase, 1 digit and one special character with min 8 characters"),
  confirmPassword: Joi.string().required().equal(Joi.ref('newPassword')).error(() => 'Password must match.')
});




const ForgotPassword = () => {
  const {token, setToken, setUser, redirectToPrivateRoute} = useContext(AuthContext);
  const [frontendError, setFrontendError] = useState(null);
  const [forgotPasswordFrontendError, setForgotPasswordFrontendError] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpDialogOpen, setOtpDialogOpen] = useState(false);
  const {location: {search: queryString}, history} = useReactRouter();
  const inviteToken = queryString.length > 0 && queryStringToObj(queryString)['invite-token'];

  const {isLoading: sendOtpLoading, run: sendOtp, error: sendOtpError} = usePost('/auth/send-otp', {phoneNumber}, {
    onResolve: () => setOtpDialogOpen(true)
  });

  const {isLoading: forgotPasswordLoading, run: forgotPassword, error: forgotPasswordError, setError: setForgotPasswordError} = usePost(
    '/auth/forgot-password', {phoneNumberOrEmail: phoneNumber, otp, newPassword}, {
    onResolve: ({msg}) => {
      alert(msg);
      history.push('/login');
    }
  });

  const phoneNumberChange = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
    // if(!email) {
    //   setPhoneNumberOrEmail(phoneNumber);
    // }
  }

  if (token) return redirectToPrivateRoute(inviteToken);

  return (<div>
        <nav className="uk-navbar-container uk-box-shadow-small" style={{background: "#fff"}} data-uk-navbar>

          <div className="uk-navbar-left">

              <ul className="uk-navbar-nav">
                  <li className="uk-active">
                    <a href="/" target="_blank" rel="noopener noreferrer">
                        <img src={LogoFullDark } style={{height: "40px"}} alt=""/>
                    </a>
                  </li>
              </ul>

          </div>

          <div className="uk-navbar-right">

              <ul className="uk-navbar-nav">        
                  <li>
                      <a href="/" className="uk-visible@s" target="_blank" rel="noopener noreferrer">Home</a>
                  </li>

                  <li style={{marginLeft: "15px"}}>
                    <a className="uk-button uk-button-primary" style={{borderRadius: "0px"}} href="/register" target="_blank" rel="noopener noreferrer">Login</a>
                  </li>
              </ul>

          </div>

        </nav>
        <div className="flex flex-row overflow-y-auto" style={{height: "calc(100vh - 120px)"}}>
          <div className="w-100 w-50-ns uk-visible@s">
            <div className="uk-heading ph6-ns pt6-ns pt4 ph4">
              <div className="uk-margin-auto" style={{maxWidth: "500px"}}>
                <div className="flex flex-column justify-between">
                  <div>
                    <h2 className="uk-color-primary">
                    Create, Sign and Store Legal Contracts & Agreements
                    </h2>
                    <p className="uk-text-medium">
                    A complete document life-cycle management platform that's easy to use, highly flexible and amazingly secure.
                    </p><ul className="uk-list">
                    <li>
                      <div className="flex items-center">
                        <img src={GreenTick} alt="" srcset=""/>
                        <div className="uk-text-large uk-text-muted ml3">
                          It's Easy
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <img src={GreenTick} alt="" srcset=""/>
                        <div className="uk-text-large uk-text-muted ml3">
                          It's Secure
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <img src={GreenTick} alt="" srcset=""/>
                        <div className="uk-text-large uk-text-muted ml3">
                          It's Legal
                        </div>
                      </div>
                    </li>
                  </ul>

                    </div>
                  {/* <div>
                    <img src={RegisterIllustration} alt="a men and a women sitting on tro different desks and working ontheir computers"/>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 w-50-ns">
            <div className="pa6-ns pa4">
              <div className="uk-margin-auto" style={{maxWidth: "400px"}}>
                <h2 className="uk-color-primary">Forgot Password</h2>
                <form>
                <FormError backendError={sendOtpError} frontendError={frontendError} /> 
                  <div className="uk-margin">
                    <label className="uk-form-label">Phone no.</label>
                    <PhoneInput
                      className="uk-input"
                      defaultCountry="IN"
                      value={phoneNumber}
                      onChange={phoneNumberChange} 
                      placeholder="xxx-xxx-xxxx"
                    />
                  </div>
                
                  <button
                      className="uk-button uk-button-primary w-100" type="submit"
                      loading={sendOtpLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        const validation = Joi.validate({phoneNumber}, schema);
                        if (validation.error) setFrontendError(validation.error);
                        else sendOtp();
                      }}
                  >Send OTP</button>
                </form>
                <div className="uk-margin">
                <Link className="uk-link uk-color-primary" to={inviteToken ? `/login?invite-token=${inviteToken}`: `/login`}>
                  Login
                </Link>
                </div>
                
                <div className="uk-margin">
                  <div className="uk-text-large">
                    Do not have an account?
                  </div>
                  <Link  to={inviteToken ? `/register?invite-token=${inviteToken}`: `/register`} className="uk-button uk-button-secondary mt2" type="button">
                    Register Now
                  </Link>
                </div>

              </div>
            </div>          
          </div>
        </div>
        <footer className="uk-navbar-container uk-position-absolute uk-position-bottom" data-uk-navbar>
          <div className="uk-navbar-left uk-visible@s">
              <ul className="uk-navbar-nav">
                  <li>
                      <a href="http://veriscript.io/" className="" style={{minHeight: "40px"}} target="_blank" rel="noopener noreferrer">All rights reserved by Veriscript Pvt. Ltd.</a>
                  </li>
              </ul>
          </div>
          <div className="uk-navbar-right">

              <ul className="uk-navbar-nav">
                  <li>
                      <a href="//terms-and-conditions" style={{minHeight: "40px"}} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                  </li>
              </ul>
          </div>
        </footer>
          <Dialog
            isOpen={isOtpDialogOpen} isCloseButtonShown={true} title={`Please enter the OTP sent to: ${phoneNumber}`}
            onClose={() => setOtpDialogOpen(false)} canOutsideClickClose={false} canEscapeKeyClose={false}
          >
            <form className="pa3">
              <FormError backendError={forgotPasswordError} frontendError={forgotPasswordFrontendError}/>
              <InputGroup
                type='password'
                placeholder="New Password" className="mb3" value={newPassword} large={true} 
                onChange={wireEventValue(setNewPassword)}
                autocomplete="off"
              />

              <br />

              <InputGroup
                type='password'
                placeholder="Confirm Password" className="mb3" value={confirmPassword} large={true} 
                onChange={wireEventValue(setConfirmPassword)}
                autocomplete="off"
              />

              <br />

              <InputGroup placeholder="OTP" value={otp} onChange={wireEventValue(setOtp)} large={true} />

              <Button 
                text="Verify" intent="primary" fill={true} large={true} loading={forgotPasswordLoading} className="mt3" type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  const validation = Joi.validate({phoneNumberOrEmail: phoneNumber, otp, newPassword, confirmPassword}, finalSchema);
                  if (validation.error) setForgotPasswordFrontendError(validation.error);
                  else forgotPassword();
                  
                }}
              />
              <div className="black-50 pt2 f6">
                (Or close this dialog to modify the phone number.)
              </div>
            </form>
          </Dialog>
     </div>);
};

export default ForgotPassword;
