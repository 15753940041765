import AuthContext from '../../components/contexts/Auth';
import React, { useContext, useEffect } from 'react';
import useReactRouter from 'use-react-router'; 
import {queryStringToObj} from '../../utils/string';

const OAuthFailure = () => {
   
    // const {redirectToPrivateRoute, redirectToPublicRoute , isTokenValid, validatingToken} = useContext(AuthContext);
    // const {location: {search: queryString}, history} = useReactRouter();
    // const inviteToken = queryString.length > 0 && queryStringToObj(queryString)['invite-token'];

    // if (isTokenValid) { 
    //     return redirectToPrivateRoute(inviteToken)
    // } else if(!validatingToken && !isTokenValid) {
    //     return redirectToPublicRoute(inviteToken)
    // }
    return (<div>
        <h3>Failed due to some issue</h3>
    </div>)
};

export default OAuthFailure;