import { Alert, Button, Card, Elevation, Intent } from '@blueprintjs/core';
import React, { useState } from 'react';
import {Route} from 'react-router-dom';
import useReactRouter from 'use-react-router';

import IfAuth from '../components/IfAuth';
import ContextualNav from '../components/nav/ContextualNav';
import { useGet, useSubscription } from '../utils/hooks';

import './deploy.css'


const deploySubscriptionForSite = `subscription deploySubscription ($siteId:uuid){
  deploys (
    where: {
      site_id: {_eq: $siteId}
    }
    order_by: {created_at:desc}
  ){
    id
    commit
    commit_message
    site {
      name
      domain
    }
    created_at
    is_published
  }
}`;


const LatestDeploysForSite = ({site}) => {
  const {history} = useReactRouter();
  const [publishThisDeploy, setPublishThisDeploy] = useState({})
  const [showWarning, setShowWarning] = useState(false)


  const {values: deployResults, tmpValues: tmpDeploys, connecting: fetchingDeploys,
    setTmpValues: setTmpDeploys} = useSubscription(deploySubscriptionForSite, {siteId: site && site.id});

  const {isLoading: sitePublishing, run: publishDeploy, error: sitePublishError} = useGet(`/deploy/publish/${publishThisDeploy.id}`, {}, {
    onResolve: (response) => {
        console.log(response)
        setShowWarning(false)
        history.push(`/deploy/${publishThisDeploy.id}`)
    }
  });

  const deploySiteVersion = (build) => {
    console.log(build.id)
    setPublishThisDeploy(build)
    setShowWarning(true);
    
  }

  const deploys = deployResults && deployResults[0] ? deployResults[0].deploys: []
  return (<IfAuth>
    <div className='w-100 ph2 pv4 ml4' style={{
      background: 'white',
      height: 'calc(100vh - 100px)'
    }}>
      <h3>Latest Builds</h3>
      <div className='w-100' style={{
        overflow: 'auto',
        height: 'calc(100% - 40px)'
      }}>
        {
          deploys.map(build => {
            return (
            <Card elevation={Elevation.TWO} className={'w-100'} key={build.id}>
              <div className='flex justify-between deploy-card-head' onClick={() => {
                history.push('/deploy/' + build.id)
               }}>
                <div>{build.site.name}</div> <div>{'completed'}</div>
              </div>
              <div className='flex justify-between deploy-card-body'>
                <div className="w-75">Deploy Preview: {build.commit_message}@{build.commit}</div>
                <div>
                  {
                    (build.id == site.published_deploy_id) ? 
                    <Button intent='success' disabled={true}>Live</Button>:
                    <Button intent='primary'  onClick={() => {
                      deploySiteVersion(build)
                    }}>Publish</Button>
                  }
                  
                </div>
              </div>
            </Card>
            )
          })
        }

        {
          deploys.length === 0 ? <h4>No Deploys found</h4> : <></>
        }
      </div>
    </div>

    <Alert
        
        cancelButtonText="Cancel"
        confirmButtonText="Publish this deploy"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={showWarning}
        loading={sitePublishing}
        onCancel={() => {setShowWarning(false)}}
        onConfirm={publishDeploy}
    >
        <p>
            Are you sure you want to publish this version?
        </p>
    </Alert>
  </IfAuth>);
};

export default LatestDeploysForSite;