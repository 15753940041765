import AuthContext from '../../components/contexts/Auth';
import React, { useContext, useEffect } from 'react';
import useReactRouter from 'use-react-router'; 
import {queryStringToObj} from '../../utils/string';

const OAuthSuccess = () => {
   
    const {redirectToPrivateRoute, redirectToPublicRoute , isTokenValid, validatingToken, setToken} = useContext(AuthContext);
    const {location: {search: queryString}, history} = useReactRouter();
    const token = queryString.length > 0 && queryStringToObj(queryString)['token'];

    if(token) {
        setToken(token);
        window.location.href = '/dashboard';
    }

    return (<div>
        <h3>Success</h3>
    </div>)
};

export default OAuthSuccess;