import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import {Classes} from '@blueprintjs/core';
import Joi from '@hapi/joi';
import { usePost, usePut } from '../../utils/hooks';
import { wireEventValue } from '../../utils/func';
import FormError from '../FormError';

const SiteForm = ({onSuccess, isEdit = false, values = {}}) => {
    const [name, setName] = useState(values.name)
    const [domain, setDomain] = useState(values.domain)
    const [gitBranch, setGitBranch] = useState(values.git_branch)
    const siteId = values.id;
    const [frontendError, setFrontendError] = useState()

    const {isLoading: siteCreating, run: createSite, error: siteCreationError} = usePost('/sites', {name, domain, gitBranch}, {
        onResolve: (response) => {
            if(onSuccess) {
                onSuccess(response)
            }
        }
    });

    const {isLoading: siteUpdating, run: updateSite, error: siteUpdateError} = usePut(`/sites/${siteId}`, {name, domain, gitBranch}, {
        onResolve: (response) => {
            if(onSuccess) {
                onSuccess(response)
            }
        }
    });

    const schema = Joi.object().keys({
        name: Joi.string().required(),
        domain: Joi.string().required()
    });

    return (
    <form>
        <FormError backendError={siteCreationError} frontendError={frontendError} />
        <div className="uk-margin">
            <label className="uk-form-label">Site Name</label>
            <input 
            type={'text'} className="uk-input"
            placeholder="mirrar-dev"
            value={name} required={true}
            onChange={wireEventValue(setName)}
            />
        </div>

        <div className="uk-margin">
            <label className="uk-form-label">Git branch</label>
            <input 
            type={'text'} className="uk-input"
            placeholder="mirrar-dev"
            value={gitBranch} required={true}
            onChange={wireEventValue(setGitBranch)}
            />
        </div>

        <div className="uk-margin">
            <label className="uk-form-label">Domain</label>
            <div className='flex items-center'>
                <span className='f4'>https://</span>
                <input 
                    type={'text'} className="uk-input"
                    placeholder="mirrar-dev"
                    value={domain} required={true}
                    onChange={wireEventValue(setDomain)}
                />
                <span className='f4'>.mirrar.com</span>
            </div>
        </div>
        
        
        <button loading={siteCreating} type="submit"
            className="uk-button uk-button-primary"
            onClick={(e) => {
                e.preventDefault();
                setFrontendError(null);

                const validation = Joi.validate({name, domain}, schema);
                if (validation.error) setFrontendError(validation.error);

                if(isEdit) {
                    updateSite()
                } else {
                    createSite()
                }
            }}
        >
            Create Site
        </button>
    </form>
    )
};

export default SiteForm;
