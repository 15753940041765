export const capitalise = (s) => {
  return s[0].toUpperCase() + s.substring(1);
}

export const random = () => {
  // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  return Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
}

export const isEmail = (email) => {
  // eslint-disable-next-line 
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const isString = (str) => typeof(str) === "string";

export const retractString = (str, length) => `${str.substring(0, length)} ${str.length > length ? "..." : ""}`;

export const contains = (haystack, needle) => haystack.indexOf(needle) > -1;

export const queryStringToObj = (queryString) => [...new URLSearchParams(queryString).entries()]
  .reduce((prev, [key,val]) => {prev[key] = val; return prev}, {})
;