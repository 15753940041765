import React from 'react';
import {Navbar, Alignment, Classes, Icon, Button} from '@blueprintjs/core';
import { Select } from "@blueprintjs/select";
import useReactRouter from 'use-react-router';
import SiteSelect from '../SiteSelect';

const ContextualNav = ({icon, href, title, rightElement, rightElementClassName=''}) => {
  const {history} = useReactRouter();
  return (<Navbar style={{maxWidth: "100%"}} fixedToTop={true}>
    <Navbar.Group align={Alignment.LEFT}>
      <button
        className={`${Classes.BUTTON} ${Classes.MINIMAL}`}
        onClick={(e) => {
          e.preventDefault();
          if (href) {
            history.push(href);
          } else {
            history.push('/dashboard');
          }
        }}
      >
        <Icon icon={icon || "arrow-left"} />
      </button>

      <Navbar.Heading>
        <div className="ml2 uk-text-large">{title}</div>
      </Navbar.Heading>
    </Navbar.Group>

    <Navbar.Group className={rightElementClassName} align={Alignment.RIGHT}>

      <SiteSelect items={[
        {id: '1', 'title': 'Site 1'},
        {id: '2', 'title': 'Site 2'},
      ]}>
      </SiteSelect>

      <button className={`${Classes.BUTTON}`} onClick={
        () => {
          localStorage.clear()
          window.location.reload()
        }
      }>
        Sign Out
        </button>
    </Navbar.Group>
  </Navbar>);
};

export default ContextualNav;
