import React from 'react';
import {Position, Toaster, Intent} from "@blueprintjs/core";

const ToastContext = React.createContext();

const globalToaster = Toaster.create({
  position: Position.TOP,
});


export const ToastContextProvider = ({children}) => {
  const addToast = ({message, intent}) => globalToaster.show({message, intent, timeout: 2400});
  const addSuccessToast = (t) => addToast({...t, intent: Intent.SUCCESS});
  const addErrorToast = (t) => addToast({...t, intent: Intent.DANGER});
  const addWarningToast = (t) => addToast({...t, intent: Intent.WARNING});

  return (<ToastContext.Provider value={{addToast, addSuccessToast, addErrorToast, addWarningToast}}>
    {children}
  </ToastContext.Provider>);
};

export default ToastContext;