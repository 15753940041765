import React from 'react';
import {Callout} from '@blueprintjs/core';

import {accessByKey} from '../utils/object';
import {isArray} from '../utils/array';
import {contains} from '../utils/string';

const getUniquenessContraintMessage = (message) => {
  if (contains(message, `duplicate key value violates unique constraint "users_email_key"`))
    return "This email is already in use. Please enter a unique email id";
   
  return message;
}

const isJoiError = (error) => accessByKey(error, 'error.isJoi') || error.isJoi;

const isGqlError = (error) => isArray(error) && accessByKey(error, '0.extensions') && accessByKey(error, '0.message');

const getMessage = (frontendError, backendError) => {
  // (0) no errors
  if (!frontendError && !backendError) return false;

  // (1) if there is a frontendError, throw it first
  if (frontendError && isJoiError(frontendError)) return frontendError.details[0].message;

  // (2) check for backend Joi error
  if (backendError && isJoiError(backendError)) return backendError.error.details[0].message;

  // (3) backend api errors
  if (backendError && backendError.msg) return backendError.msg;

  // (4) might be a gql error
  if (backendError && isGqlError(backendError)) {
    if (contains(backendError[0].message, "Uniqueness violation"))
      return getUniquenessContraintMessage(backendError[0].message);
  };

  // hyper generic in case the app fucks up
  return "An error occured, please try again."; 
};

const FormError = ({frontendError, backendError}) => {
  const message = getMessage(frontendError, backendError);
  return (message && <Callout className="mv2" intent="danger">
    {message}
  </Callout>);
};

export default FormError;