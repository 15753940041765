// Author : Shivek Khurana

// 3rd party imports come first
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch, Link} from 'react-router-dom';
import './utils/tachyons.min.css';
import * as Sentry from '@sentry/browser';
import { Button, Card, Elevation } from "@blueprintjs/core";

// Followed by a newline and custom imports
import './index.scss';
import  './styles.css';
import  './index.css';
import {default as async} from './utils/asyncImport';
import {AuthContextProvider} from './components/contexts/Auth';
import {ToastContextProvider} from './components/contexts/Toast';

// Pages to be loaded in first network call
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import OAuth from './pages/OAuth';
import SitesPage from './pages/SitesPage';
import SitesDetailPage from './pages/SitesDetailPage';
import DeployDetailPage from './pages/DeployDetailPage';
import TeamPage from './pages/TeamPage';

// Pages to be loaded on demand
const HomePage = async(import('./pages/HomePage'));
const Register = async(import('./pages/Register'));
const Dashboard = async(import('./pages/Dashboard'));
// Setup Sentry
const env = process.env.REACT_APP_BUILD_ENV;
if (env !== 'dev') {
  Sentry.init({
    dsn: "https://8eed918f89104cf79e267098ca641d20@sentry.io/1867969",
    environment: env
  });
}

const providers = [
  [AuthContextProvider, {}],
  [ToastContextProvider, {}],
];

const combineProviders = (providers) => {
  const RootProvider = ({children}) => providers.reduceRight((tree, [Provider, props]) => 
    (<Provider {...props}>{tree}</Provider>), children)
  ;
  return RootProvider;
};

const RootProvider = combineProviders(providers);

const App = () => (
  <RootProvider>
    <DndProvider backend={HTML5Backend}>

      <BrowserRouter>
        <Switch>
          <Route path="/dashboard" component={Dashboard}/>
          <Route exact={true} path="/login" component={Login} />
          <Route exact={true} path="/register" component={Register} />
          <Route exact={true} path="/forgot-password" component={ForgotPassword} />
          <Route exact={true} path="/oauth" component={OAuth} />
          <Route exact={true} path="/sites" component={SitesPage} />
          <Route exact={true} path="/sites/:id" component={SitesDetailPage} />
          <Route exact={true} path="/deploy/:id" component={DeployDetailPage} />
          <Route exact={true} path="/team" component={TeamPage} />
          <Route component={HomePage} />
        </Switch>
      </BrowserRouter>

    </DndProvider>
  </RootProvider>
);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
