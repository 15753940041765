import { Alert, Button, Dialog, Intent, Overlay } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import {Route} from 'react-router-dom';
import useReactRouter from 'use-react-router';

import IfAuth from '../components/IfAuth';
import ContextualNav from '../components/nav/ContextualNav';
import { Cell, Column, Table } from "@blueprintjs/table";
import { useDelete, useGet } from '../utils/hooks';
import SiteForm from '../components/forms/siteForm';

import './siteList.css'

const SitesPage = () => {
  const {history, match} = useReactRouter();
  const [sites, setSites] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [currentSite, setCurrentSite] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const {data: siteResponse, isLoading: fetchingSites, run: fetchSites, error: fetchSiteError} = useGet(`/sites`, null, {
    onResolve: (response) => {
      if(response.data) {
        setSites(response.data.data)
      }
    },
    onReject: error => {
      console.error("Sites error:", error);
    }
  });

  const {data: deleteSiteResponse, isLoading: deletingSites, run: deleteSiteApi, error: deleteSiteError} = useDelete(`/sites/${currentSite && currentSite.id}`, null, {
    onResolve: (response) => {
      fetchSites()
    },
    onReject: error => {
      console.error("Sites error:", error);
    }
  });

  useEffect(() => {
    fetchSites()
  }, [])

  const openEditForm = (site) => {
      setIsEdit(true)
      setCurrentSite(site);
      setShowForm(true)
  }

  const deleteSite = (site) => {
    setCurrentSite(site)
    setShowWarning(true)
  }

  const deleteSiteWarningSuccess = () => {
    deleteSiteApi()
    setShowWarning(false)
  }

  return (<IfAuth>
      <div>
          <ContextualNav></ContextualNav>
      </div>

    <div className='pa4 site-list' style={{
      background: 'white',
      marginTop: '50px'
    }}>
      <div className='f6 w-100 center mw8 mb5 justify-between items-center flex'>
        <h4>Sites</h4>
        <Button text={'Create New Site'} rightIcon="plus" intent='primary' onClick={() => {
            setIsEdit(false)
            setShowForm(true)
        }}/>
      </div>
      <div>
          <table className='f6 w-100 mw8 center' cellSpacing="0">
              <thead>
                  <tr>
                    <th className="fw6 bb b--black-20 tl pb3 pr2 bg-white">Site Name</th>
                    <th className="fw6 bb b--black-20 tl pb3 pr2 bg-white">Site URL</th>
                    <th className="fw6 bb b--black-20 tl pb3 pr2 bg-white">Github branch</th>
                    <th className="fw6 bb b--black-20 tl pb3 pr2 bg-white">Last Deployed Version</th>
                    <th className="fw6 bb b--black-20 tl pb3 pr2 bg-white">Actions</th>

                  </tr>
              </thead>
              <tbody className='lh-copy'>
                {
                  sites.map(site => {
                    return <tr key={'row-' + site.id}>
                      <td className='pv3 pr2 bb b--black-20' 
                          onClick={() => {
                            history.push(`sites/${site.id}`)
                        }}>{site.name}</td>
                      <td className='pv3 pr2 bb b--black-20'>{site.domain}</td>
                      <td className='pv3 pr2 bb b--black-20'>{site.git_branch || 'master'}</td>
                      <td className='pv3 pr2 bb b--black-20'>{site.version || '-'}</td>
                      <td className='pv3 pr2 bb b--black-20'>
                          <Button intent='primary' onClick={() => {
                              openEditForm(site)
                          }}> Edit </Button>
                          <Button intent='danger' onClick={() => {
                              deleteSite(site)
                          }}> Delete </Button>
                      </td>
                    </tr>
                  })
                }
              </tbody>
          </table>
      </div>
    </div>

    <Dialog isOpen={showForm} isCloseButtonShown={true} title='Add New Site' onClose={() => {
      setShowForm(false)
    }}>
      <div className='w-100 flex justify-center items-center pa4'>
        <SiteForm isEdit={isEdit} values={{...currentSite}} onSuccess={(response) => {
          setShowForm(false);
          fetchSites()
        }}></SiteForm>
      </div>
    </Dialog>

    <Alert
        
        cancelButtonText="Cancel"
        confirmButtonText="Delete Site"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={showWarning}
        loading={deletingSites}
        onCancel={() => {}}
        onConfirm={deleteSiteWarningSuccess}
    >
        <p>
            Are you sure you want to delete the site?
        </p>
    </Alert>
  </IfAuth>);
};

export default SitesPage;