export const chunk = (array, size) => {
  return array.reduce((res, item, index) => {
    if (index % size === 0) { res.push([]); }
    res[res.length-1].push(item);
    return res;
  }, []);
}

export const isArray = (objInContext) => {
  return !!objInContext && Array === objInContext.constructor;
}

export const range = (start, stop, step = 1) => {
  return Array(stop - start).fill(start).map((x, y) => x + y * step);
}

export const removeEmptyElements = (arr) => {
  return arr.filter(el => el !== null);
}