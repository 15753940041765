import React, {  } from 'react';
import useReactRouter from 'use-react-router';

import IfAuth from '../components/IfAuth';


const DeploymentLog = ({build}) => {
  const {match} = useReactRouter();
  
  
  return (<IfAuth>
    <div className='pa4 site-list' style={{
      background: 'white'
    }}>
      <h3>Deployment logs will come here...</h3>
    </div>

    
  </IfAuth>);
};

export default DeploymentLog;