import React from 'react';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';

import {Classes} from '@blueprintjs/core';


const LoadingComponent = ({isLoading, error}) => {
  if (isLoading) {
    // Handle the loading state
    return (<div className={`fixed w-100 vh-100 ${Classes.SKELETON}`} />);
  } else if (error) {
    console.log(error);
    // Handle the error state
    return <div>Sorry, there was a problem loading the page.</div>;
  } else {
    return null;
  }
};

LoadingComponent.propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.object
};

function asyncImport(importer) {
  return Loadable({
    loader: () => importer,
    loading: LoadingComponent
  });
}

export default asyncImport;
