import {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  useContext
} from 'react';
import {
  useAsync
} from 'react-async';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import config from '../config';
import {
  partial
} from './func';
import {
  random
} from './string';
import AuthContext from '../components/contexts/Auth';

var tokenUpdateEvent = document.createEvent('CustomEvent');
tokenUpdateEvent.initCustomEvent('tokenUpdate', true, false);

var lastTokenUpdatedAt;

export const useEventListener = (eventName, handler, element = global) => {
  const savedHandler = useRef();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = event => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};

export const resetAuthState = ({token, tokenExpiresAt, refreshTokenExpiresAt, user}) => {
  
}

const buildHeaders = (additionalHeaders = {}) => ({
  ...{
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
  ...additionalHeaders
});


const useRest = (method, endPoint, args, raOpts = {}, additionalHeaders = {}, base = config.restApiBase) => {
  return useAsync({
    deferFn: ([runArgs]) => new Promise(
      (resolve, reject) => {
        const refreshAuthLogic = failedRequest => {
          return axios.get(base + `/auth/refresh-token`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }).then(tokenRefreshResponse => {
            let {
              token,
              tokenExpiresAt,
              refreshTokenExpiresAt,
              user
            } = tokenRefreshResponse;
            let currentTime = Date.now();
            if(!lastTokenUpdatedAt || (lastTokenUpdatedAt - currentTime > 12000)) {
              lastTokenUpdatedAt = currentTime;
              if (token) localStorage.setItem('token', token);
              if (user) localStorage.setItem('user', JSON.stringify(user));
              if (tokenExpiresAt) localStorage.setItem('tokenExpiresAt', tokenExpiresAt);
              if (refreshTokenExpiresAt) localStorage.setItem('refreshTokenExpiresAt', refreshTokenExpiresAt);
  
              failedRequest.response.config.headers['Authorization'] = 'Bearer ' + token;
              document.dispatchEvent(tokenUpdateEvent)
            }

            return Promise.resolve();
          }).catch(err => {
            // debugger
            console.log("Error in refreshing: ",err)
            localStorage.clear();
            window.location = '/';

            return Promise.reject();
          });
        }

        // Instantiate the interceptor (you can chain it as it returns the axios instance)
        createAuthRefreshInterceptor(axios, refreshAuthLogic, {
          pauseInstanceWhileRefreshing: true,
          statusCodes: [401, 403] //added 400 for graphql queries
        });

        return axios({
            method,
            headers: buildHeaders(additionalHeaders),
            url: `${base}${endPoint}`,
            [method === 'get' ? 'params' : 'data']: args || runArgs,
            withCredentials: true
          })
          .then(({
            data
          }) => {
            resolve(data)
          })
          .catch(error => {
            if (error && error.response) {
              reject(error.response.data);
            } else {
              reject({
                msg: "An unknown error occurred."
              });
            }
          })
      }
    ),
    ...raOpts // react-async options
  })
};

export const useGet = partial(useRest, 'get');
export const usePost = partial(useRest, 'post');
export const usePut = partial(useRest, 'put');
export const useDelete = partial(useRest, 'delete');

const useGraph = (type, query, variables, raOpts = {}) => useAsync({
  deferFn: ([runArgsVariables, runArgsQuery]) => new Promise(
    (resolve, reject) => axios({
      method: 'post',
      headers: buildHeaders(),
      url: config.graphQLApiBase,
      data: {
        query: `${query || runArgsQuery}`,
        variables: variables || runArgsVariables
      }
    })
    .then(res => resolve(res.data.data))
    // possible unhandled error here
    .catch(err => reject(err && err.response && err.response.data && err.response.data.errors))
  ),
  ...raOpts
});

export const useSubscription = (query, variables = {}) => {
  const [values, setValues] = useState([]);
  const [tmpValues, setTmpValues] = useState([]); // set values for optimistic updates
  const [connecting, setConnecting] = useState(true);
  const [socket, setSocket] = useState(null);
  const [error, setError] = useState(null);
  const id = random();

  const openSocket = () => new Promise((resolve, reject) => {
    const socket = new WebSocket(config.graphqlWSBase, 'graphql-ws');
    socket.onopen = () => {
      setConnecting(false);
      setSocket(socket);
      resolve(socket);
    }
    socket.onerror = (e) => {
      reject(socket);
      setError(e);
    }
  });

  const onMessage = e => {
    const data = JSON.parse(e.data);
    if (data.type === 'data' && data.id === id) {
      setValues([...values, data.payload.data]);
      setTmpValues(null);
    }
  };

  useLayoutEffect(() => {
    if (!socket) {
      openSocket()
        .then(socket => {
          setConnecting(false);
          socket.addEventListener('message', onMessage);
          socket.send(JSON.stringify({
            type: 'connection_init',
            payload: {
              headers: buildHeaders()
            }
          }));
          socket.send(JSON.stringify({
            id,
            type: 'start',
            payload: {
              query,
              variables
            }
          }));
        })
        .catch(err => {
          console.log('Error in opening socket');
          console.log(err);
        });
    }

    return () => {
      if (socket) {
        socket.send(JSON.stringify({
          type: 'connection_terminate',
          payload: {
            headers: buildHeaders()
          }
        }));
        socket.removeEventListener('message', onMessage);
        socket.close();
      }
    };
    //eslint-disable-next-line
  }, [socket]);

  return {
    connecting,
    values,
    id,
    setTmpValues,
    tmpValues,
    error
  };
}

export const useQuery = partial(useGraph, 'query');
export const useMutation = partial(useGraph, 'mutation');

// https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx
export const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

// https://github.com/siddharthkp/use-timeout
export const useTimeout = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setTimeout(tick, delay)
      return () => clearTimeout(id)
    }
  }, [delay]);
};

// https://github.com/donavon/use-interval
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args) => savedCallback.current(...args);

    if (delay !== null) {
      const id = setInterval(handler, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export default useInterval;