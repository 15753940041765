import {useContext} from 'react';
import useReactRouter from 'use-react-router'; 
import {queryStringToObj} from '../utils/string';

import AuthContext from '../components/contexts/Auth';

const IfAuth = ({children}) => {
  const {location: {search: queryString}, history} = useReactRouter();
  const inviteToken = queryString.length > 0 && queryStringToObj(queryString)['invite-token'];

  const {isTokenValid, validatingToken, redirectToPublicRoute, token} = useContext(AuthContext);   

  if (!token) return redirectToPublicRoute(inviteToken);
  else return children;
};

export default IfAuth;