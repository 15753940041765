const ext = {
  pagesChunkSize: 4,
  pagesBeforeLoadMore: 4,
  maxUploadFileSize: 4 * 1024, // in kilobytes
  pageWidth: 594,
  pageHeight: 841,
  purposeMaxLength: 15,
  recyclerPadding: 8,
  collaboratorCardWidth: 106,
  collaboratorCardHeight: 50,
  initialPagesToLoad: 3
}

const dev = {
// const staging = {
  restApiBase: 'http://localhost:3000',

  graphQLApiBase: 'http://localhost:8080/v1alpha1/graphql',

  graphqlWSBase: 'ws://localhost:8080/v1/graphql',

  utilsBase: 'https://utils.mirrar.io',
  frontendBase: 'http://localhost:5000',
  aadhaarBase: 'https://preprod.aadhaarapi.com',
  signingBase: 'https://signing.mirrar.io',
  googleClientId: '1021021275901-8fqh15chm5lgkfpc98m2iuu0n8iiq2j5.apps.googleusercontent.com',
  outlookClientId: '1345a4ba-b00b-4be2-aad9-6b59717020b5',
  razorpayKeyId: 'rzp_test_XI9d0RQhMWbeuG',
  ...ext
};

const staging = {
// const dev = {
  restApiBase: 'https://api.mirrar.io',
  graphQLApiBase: 'https://graph.mirrar.io/v1alpha1/graphql',
  graphqlWSBase: 'wss://graph.mirrar.io/v1/graphql',
  utilsBase: 'https://utils.mirrar.io',
  frontendBase: 'https://app.mirrar.io',
  aadhaarBase: 'https://preprod.aadhaarapi.com',
  googleClientId: '1021021275901-8fqh15chm5lgkfpc98m2iuu0n8iiq2j5.apps.googleusercontent.com',
  outlookClientId: '1345a4ba-b00b-4be2-aad9-6b59717020b5',
  razorpayKeyId: 'rzp_test_XI9d0RQhMWbeuG',
  signingBase: 'https://signing.mirrar.io',
  ...ext
};

const prod = {
  restApiBase: 'https://api.mirrar.io',
  graphQLApiBase: 'https://graph.mirrar.io/v1alpha1/graphql',
  graphqlWSBase: 'wss://graph.mirrar.io/v1/graphql',
  utilsBase: 'https://utils.mirrar.io',
  frontendBase: '/',
  aadhaarBase: 'https://prod.aadhaarapi.com',
  googleClientId: '1021021275901-8fqh15chm5lgkfpc98m2iuu0n8iiq2j5.apps.googleusercontent.com',
  outlookClientId: '1345a4ba-b00b-4be2-aad9-6b59717020b5',
  razorpayKeyId: 'rzp_live_d5qZVCe1J7yGi9',
  signingBase: 'https://signing.prod.mirrar.io',
  ...ext
};

const buildEnv = process.env.REACT_APP_BUILD_ENV;

export default buildEnv === 'dev' ? dev : buildEnv === 'staging' ? staging : prod;
