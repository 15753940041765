import { Button } from '@blueprintjs/core';
import React from 'react';
import {Route} from 'react-router-dom';
import useReactRouter from 'use-react-router';

import IfAuth from '../components/IfAuth';
import ContextualNav from '../components/nav/ContextualNav';

const TeamPage = () => {
  const {match} = useReactRouter();
  const members = [
    {name: 'Ankit', email: 'ankit@sdm.com', role: 'admin'},
    {name: 'akhil', email: 'akhil@sdm.com', role: 'admin'},
  ]
  return (<IfAuth>
    <div>
      <ContextualNav></ContextualNav>
    </div>
    <div className='w-100 pa4' style={{
      background: 'white',
      marginTop: '50px'
    }}>
      <div className='f6 w-100 center mb5 justify-between items-center flex'>
        <h3>Members</h3>
        {/* <Button text={'Add New Member'} rightIcon="plus" intent='primary'></Button> */}
      </div>
        <table className='f6 w-100 center' cellSpacing="0">
              <thead>
                  <tr>
                    <th className="fw6 bb b--black-20 tl pb3 pr2 bg-white">#</th>
                    <th className="fw6 bb b--black-20 tl pb3 pr2 bg-white">Name</th>
                    <th className="fw6 bb b--black-20 tl pb3 pr2 bg-white">Email</th>
                    <th className="fw6 bb b--black-20 tl pb3 pr2 bg-white">Role</th>
                    <th className="fw6 bb b--black-20 tl pb3 pr2 bg-white">Actions</th>
                  </tr>
              </thead>
              <tbody className='lh-copy'>
                {
                  members.map((member, index) => {
                    return <tr>
                      <td className='pv3 pr2 bb b--black-20'>{index + 1}</td>
                      <td className='pv3 pr2 bb b--black-20'>{member.name}</td>
                      <td className='pv3 pr2 bb b--black-20'>{member.email}</td>
                      <td className='pv3 pr2 bb b--black-20'>{member.role}</td>
                      <td className='pv3 pr2 bb b--black-20'>
                        <Button text={'Disable'} intent='danger'/>
                      </td>
                    </tr>
                  })
                }
              </tbody>
          </table>
    </div>
  </IfAuth>);
};

export default TeamPage;