import React, {useContext, useState} from 'react';

import AuthContext from '../components/contexts/Auth';

import useReactRouter from 'use-react-router'; 
import {queryStringToObj} from '../utils/string';
import {useGet, usePost} from '../utils/hooks';
import {Link} from 'react-router-dom';
import LogoFullDark from "../images/logo.png";

import config from '../config'

const Login = () => {
  const {token, setToken, setUser, redirectToPrivateRoute, setTokenExpiresAt, setAuthCredentials, isTokenValid} = useContext(AuthContext);
  const [frontendError, setFrontendError] = useState(null);

  const {location: {search: queryString}, history} = useReactRouter();

  const signInGoogle = () => {
    // sign in with google
    window.location.href = config.restApiBase + '/auth/google';
  }

  return (<div className="h-100">
    <nav className="uk-navbar-container uk-box-shadow-small" style={{background: "#fff"}} data-uk-navbar>

      <div className="uk-navbar-left">

          <ul className="uk-navbar-nav">
              <li className="uk-active">
                <a href="/" target="_blank" rel="noopener noreferrer">
                    <img src={LogoFullDark} style={{height: "30px"}} alt=""/>
                </a>
              </li>
          </ul>

      </div>

      <div className="uk-navbar-right">

          <ul className="uk-navbar-nav">        
              <li>
                  <Link to={'/'} className="uk-visible@s" target="_blank" rel="noopener noreferrer">Home</Link>
              </li>

              <li style={{marginLeft: "15px"}}>
                <Link className="uk-button uk-button-primary" style={{borderRadius: "0px"}} to={'login'}>Login</Link>
              </li>
          </ul>

      </div>

    </nav>

    <div class="w-100 flex items-center justify-center" style={{height: 'calc(100% - 60px)'}}>
      <button className="uk-button uk-button-primary" onClick={signInGoogle}>Sign in with Google</button>
    </div>
    <footer className="uk-navbar-container uk-position-absolute uk-position-bottom" data-uk-navbar>
     
    </footer>
     </div>);
};

export default Login;
