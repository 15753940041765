import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import useInterval, { useGet } from '../../utils/hooks';

const AuthContext = React.createContext();

const redirectToPublicRoute = (inviteToken) => {
  if(inviteToken) {
    return <Redirect to={`/login?invite-token=${inviteToken}`} />
  } else {
    return <Redirect to="/login" />
  }
};

export const AuthContextProvider = ({children}) => {

  const publicRoutes = [
    '/login',
    '/oauth/success',
    '/oauth/failure'
  ]

  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [tokenExpiresAt, setTokenExpiresAt] = useState(localStorage.getItem('tokenExpiresAt'));
  const [refreshTokenExpiresAt, setRefreshTokenExpiresAt] = useState(localStorage.getItem('refreshTokenExpiresAt'));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') || "{}"));
  const [inviteToken, setInviteToken] = useState('')
  const {data:logoutResponse, isLoading: loggingOut, run: logoutViaApi, error: logoutError} = useGet(`/auth/logout`, null, {
    onResolve: () => {
      localStorage.clear();
      window.location = "/";
    },
    onReject: error => {
      console.error("Logout error:", error);
    }
  });
  const {data: refreshTokenResponse, isLoading: refreshingToken, run: refreshTheTokenViaAPI, error: refreshTokenError} = useGet(`/auth/refresh-token`, null, {
    onResolve: ({token, tokenExpiresAt, refreshTokenExpiresAt, user}) => {
      setAuthCredentials({token, tokenExpiresAt, refreshTokenExpiresAt, user});   
    },
    onReject: (error) => {
      console.error("Refresh token:", error);
      localStorage.clear();
      window.location = "/";
    }
  });
  // const {data, isLoading: validatingToken, run: validateToken, error: validateTokenResponseError} = useGet(`/auth/validate-token`, null, {
  //   onResolve: () => {
      
  //     setIsTokenValid(true);
  //   },
  //   onReject: error => {
  //     console.error("Token validation:", error)
  //     // alert('You have been logged out of the system due to expired session. Please login again.');
  //     refreshTheTokenViaAPI();
  //   }
  // })

  const logout = () => {
    logoutViaApi();
  }

  const redirectToPrivateRoute = (inviteToken) => {
    if (inviteToken) {
      return <Redirect to={`/dashboard/organisation-invite?invite-token=${inviteToken}`} />
    } else {
      return <Redirect to="/dashboard" />
    }
  };

  const setAuthCredentials = ({token, tokenExpiresAt, refreshTokenExpiresAt, user}) => {
      setIsTokenValid(false);
      setToken(token)
      setRefreshTokenExpiresAt(refreshTokenExpiresAt);
      setTokenExpiresAt(tokenExpiresAt);
      setUser(user)
  }

  useEffect(() => {
    if (token) localStorage.setItem('token', token);
    if (user) localStorage.setItem('user', JSON.stringify(user));
    if (tokenExpiresAt) localStorage.setItem('tokenExpiresAt', tokenExpiresAt);
    if (refreshTokenExpiresAt) localStorage.setItem('refreshTokenExpiresAt', refreshTokenExpiresAt);

  }, [token, user]);

  window.addEventListener('tokenUpdate',e => {
    console.log("Token updated");
    let token = localStorage.getItem('token');
    let user =  localStorage.getItem('user');
    let tokenExpiresAt = localStorage.getItem('tokenExpiresAt');
    let refreshTokenExpiresAt = localStorage.getItem('refreshTokenExpiresAt');
    setAuthCredentials({token, tokenExpiresAt, refreshTokenExpiresAt, user})
  })

  return (<AuthContext.Provider value={{
    token, isTokenValid,
    user, 
    logout, 
    validateToken: () => {}, validatingToken: false,
    publicRoutes, redirectToPublicRoute, redirectToPrivateRoute, 
    setToken, setUser, setTokenExpiresAt, setRefreshTokenExpiresAt,
    setAuthCredentials
    }}>
    {children}
  </AuthContext.Provider>);
};

export default AuthContext;
