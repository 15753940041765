import React, { useState } from 'react';
import {Navbar, Alignment, Classes, Icon, Button, MenuItem} from '@blueprintjs/core';
import { Select } from "@blueprintjs/select";
import useReactRouter from 'use-react-router';

const renderSite = (item, {modifiers, query, handleClick}) => {
    
    const text = `${item.title}`;
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            label={item.title.toString()}
            key={item.id}
            onClick={handleClick}
            // text={highlightText(text, query)}
        />
    );
}

const SiteSelect = ({items = []}) => {
  const {history} = useReactRouter();
  const [selectedItem, setSelectedItem] = useState(items[0])
  return (
  <Select 
        items={items}
        activeItem={selectedItem}
        itemRenderer={renderSite}
        onItemSelect={(item) => {
            setSelectedItem(item)
        }}
    >
        <Button text={selectedItem.title} rightIcon="double-caret-vertical" />
    </Select>
  );
};

export default SiteSelect;
