import { Alert, Button, Dialog, Intent, Overlay } from '@blueprintjs/core';
import React, { useEffect, useState } from 'react';
import {Route} from 'react-router-dom';
import useReactRouter from 'use-react-router';

import IfAuth from '../components/IfAuth';
import ContextualNav from '../components/nav/ContextualNav';
import { Cell, Column, Table } from "@blueprintjs/table";
import { useDelete, useGet, useMutation } from '../utils/hooks';
import SiteForm from '../components/forms/siteForm';

import './siteList.css'
import LatestDeploysForSite from './LatestDeploysForSite';


const updateAutoPublishStatusMutation = `mutation ($siteId: uuid, $autoPublish: Boolean) {
  update_site(where: {id: {_eq: $siteId}}, _set: {auto_publish: $autoPublish}) {
    affected_rows
  }
}`;

const SitesDetailPage = () => {
  const {history, match: { path: matchPath, url: matchUrl, params: { id } }} = useReactRouter();
  const [site, setSite] = useState({})
  const [showForm, setShowForm] = useState(false)
  const [currentSite, setCurrentSite] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const {data: siteResponse, isLoading: fetchingSites, run: fetchSite, error: fetchSiteError} = useGet(`/sites/${id}`, null, {
    onResolve: (response) => {
      if(response.data) {
        setSite(response.data.data)
      }
    },
    onReject: error => {
      console.error("Sites error:", error);
    }
  });

  const {data: deleteSiteResponse, isLoading: deletingSites, run: deleteSiteApi, error: deleteSiteError} = useDelete(`/sites/${currentSite && currentSite.id}`, null, {
    onResolve: (response) => {
      fetchSite()
    },
    onReject: error => {
      console.error("Sites error:", error);
    }
  });

  const {data: autoPublishResponse, run: updateAutoPublishStatus} = useMutation(updateAutoPublishStatusMutation, null, { 
    onResolve: () => fetchSite() 
  });

  useEffect(() => {
    fetchSite()
  }, [])

  const openEditForm = (site) => {
      setIsEdit(true)
      setCurrentSite(site);
      setShowForm(true)
  }

  const deleteSite = (site) => {
    setCurrentSite(site)
    setShowWarning(true)
  }

  const deleteSiteWarningSuccess = () => {
    deleteSiteApi()
    setShowWarning(false)
  }

  const setAutoPublish = (autoPublish) => {
    updateAutoPublishStatus({ siteId: site.id, autoPublish})
  }

  return (<IfAuth>
      <div>
          <ContextualNav href={'/sites'}></ContextualNav>
      </div>

    <div className='pa4 site-list' style={{
      background: 'white',
      marginTop: '50px'
    }}>
      <div className='f6 w-100 center mw8 mb5 justify-between items-center flex flex-column'>

        <div className="w-100 items-center flex justify-between">
          <h2>{site && site.name}</h2>
          {
            site.auto_publish ? <Button intent="primary" onClick={() => {setAutoPublish(false)}}>Stop Auto Publishing</Button> : <Button intent="warning" onClick={() => {setAutoPublish(true)}}>Start Auto Publishing</Button>
          }
        </div>
        {
          site && site.id ? <LatestDeploysForSite site={site}></LatestDeploysForSite> : <h4>Fetching latest deploys...</h4>
        }
        
      </div>
      <div>
      </div>
    </div>

    

    <Alert
        
        cancelButtonText="Cancel"
        confirmButtonText="Delete Site"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={showWarning}
        loading={deletingSites}
        onCancel={() => {}}
        onConfirm={deleteSiteWarningSuccess}
    >
        <p>
            Are you sure you want to delete the site?
        </p>
    </Alert>
  </IfAuth>);
};

export default SitesDetailPage;