import React, {useContext, useState} from 'react';

import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';
import LogoFullDark from "../images/logo.png";

import OAuthSuccess from './oauth/Success';
import OAuthFailure from './oauth/Failure';

const OAuth = () => {

    console.log('here')
  return (<div className="h-100">
    <nav className="uk-navbar-container uk-box-shadow-small" style={{background: "#fff"}} data-uk-navbar>

      <div className="uk-navbar-left">

          <ul className="uk-navbar-nav">
              <li className="uk-active">
                <a href="/" target="_blank" rel="noopener noreferrer">
                    <img src={LogoFullDark} style={{height: "30px"}} alt=""/>
                </a>
              </li>
          </ul>

      </div>

      <div className="uk-navbar-right">

          <ul className="uk-navbar-nav">        
              <li>
                  <Link to={'/'} className="uk-visible@s" target="_blank" rel="noopener noreferrer">Home</Link>
              </li>

              <li style={{marginLeft: "15px"}}>
                <Link className="uk-button uk-button-primary" style={{borderRadius: "0px"}} to={'login'}>Login</Link>
              </li>
          </ul>

      </div>

    </nav>

    <div>
        <BrowserRouter>
            <Switch>
                <Route path="/oauth/success" component={OAuthSuccess} />
                <Route path="/oauth/failure" component={OAuthFailure} />
            </Switch>
        </BrowserRouter>
    </div>

    <footer className="uk-navbar-container uk-position-absolute uk-position-bottom" data-uk-navbar>
     
    </footer>
     </div>);
};

export default OAuth;
